exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exterior-js": () => import("./../../../src/pages/exterior.js" /* webpackChunkName: "component---src-pages-exterior-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-piso-0-js": () => import("./../../../src/pages/piso0.js" /* webpackChunkName: "component---src-pages-piso-0-js" */),
  "component---src-pages-piso-1-js": () => import("./../../../src/pages/piso1.js" /* webpackChunkName: "component---src-pages-piso-1-js" */),
  "component---src-pages-piso-2-1-js": () => import("./../../../src/pages/piso2+1.js" /* webpackChunkName: "component---src-pages-piso-2-1-js" */),
  "component---src-pages-piso-2-js": () => import("./../../../src/pages/piso2.js" /* webpackChunkName: "component---src-pages-piso-2-js" */)
}

